import React from 'react';
import { Link } from 'react-router-dom';
import { Twitter, Linkedin } from 'lucide-react';
import theme from '../theme';

const Footer = () => (
  <footer style={{backgroundColor: theme.background.dark}} className="text-white py-16 mt-16">
    <div className="container mx-auto px-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
        <div className="md:col-span-2">
          <div className="inline-block bg-white p-2 rounded-lg mb-4">
            <h3 className="text-2xl font-bold">
              <span className="text-gray-800">Mind</span>
              <span style={{color: theme.primary}}>Clave</span>
            </h3>
          </div>
          <p className="text-lg mb-4">"Built by Africans, for Africa"</p>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4 pb-2 border-b border-gray-600">Quick Links</h4>
          <ul className="space-y-2">
            {[
              { name: 'Home', path: '/' },
              { name: 'About', path: '/about' },
              { name: 'Services', path: '/services' },
              { name: 'Contact', path: '/contact' }
            ].map((item) => (
              <li key={item.name}>
                <Link to={item.path} className="hover:text-gray-300 transition duration-300">
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4 pb-2 border-b border-gray-600">Connect With Us</h4>
          <div className="flex space-x-4">
            {[
              { name: 'Twitter', icon: Twitter, url: 'https://twitter.com' },
              { name: 'LinkedIn', icon: Linkedin, url: 'https://linkedin.com' },
            ].map((item) => (
              <a 
                key={item.name} 
                href={item.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-300 hover:text-white transition duration-300"
              >
                <item.icon size={24} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="border-t border-gray-600 mt-12 pt-8 text-center">
        <p className="text-sm text-gray-300">&copy; {new Date().getFullYear()} MindClave. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;