const theme = {
    primary: '#6D28D9', // Violet-700
    primaryHover: '#5B21B6', // Violet-800
    primaryLight: '#EDE9FE', // Violet-50
    accent: '#4B5563', // Gray-600 (for buttons)
    accentHover: '#374151', // Gray-700 (for button hover)
    text: {
      primary: '#1F2937', // Gray-800
      secondary: '#4B5563', // Gray-600
      light: '#9CA3AF', // Gray-400
    },
    background: {
      light: '#F9FAFB', // Gray-50
      white: '#FFFFFF',
      dark: '#6D28D9', // Violet-700 (matching primary for footer)
    }
  };
  
  export default theme;