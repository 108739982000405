import React from 'react';
import { ArrowRight } from 'lucide-react';
import theme from '../theme';

const Button = ({ children, onClick, className = '', inverse = false }) => (
  <button 
    onClick={onClick}
    style={{
      backgroundColor: inverse ? 'white' : theme.accent,
      color: inverse ? theme.accent : 'white',
    }}
    className={`px-8 py-3 rounded-full font-semibold transition duration-300 flex items-center justify-center ${
      inverse 
        ? `hover:bg-gray-100` 
        : `hover:bg-[${theme.accentHover}]`
    } ${className}`}
  >
    {children}
    <ArrowRight className="ml-2 w-4 h-4" />
  </button>
);

export default Button;