import React from 'react';
import theme from '../theme';

const FeatureCard = ({ Icon, title, description }) => (
  <div className="bg-white p-8 rounded-lg shadow-lg transition duration-300 hover:shadow-xl border border-gray-100 hover:border-violet-200">
    <div style={{backgroundColor: theme.primaryLight}} className="rounded-full w-16 h-16 flex items-center justify-center mb-6">
      <Icon style={{color: theme.primary}} className="w-8 h-8" />
    </div>
    <h3 className="text-xl font-semibold mb-4 text-gray-800">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </div>
);

export default FeatureCard;