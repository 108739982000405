import React from 'react';
import { ChevronRight } from 'lucide-react';
import theme from '../theme';

const About = () => {
  const listItems = [
    "Expertise in AI applications for African markets",
    "Proven track record of creating innovative products",
    "Collaborations with leading African universities and tech hubs"
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-24">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">About MindClave</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Transforming Africa through Technological Innovation
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-16">
          <div className="p-8 border-l-4" style={{ borderColor: theme.primary }}>
            <p className="text-lg text-gray-600 leading-relaxed mb-8">
              MindClave is an African technology solutions provider dedicated to transforming life across the continent by building innovative products. Our team of expert engineers and data scientists leverage cutting-edge deep learning, natural language processing, and computer vision to create practical software solutions tailored to the African environment.
            </p>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h3>
            <p className="text-gray-600 leading-relaxed">
              To provide Africans with affordable, accessible, powerful AI tools and expert consultation, driving innovation in the digital age, and contributing to technological and economic growth across our continent.
            </p>
          </div>
        </div>

        <div className="mb-16 rounded-lg shadow-lg overflow-hidden" style={{ backgroundColor: theme.primary }}>
          <div className="p-8">
            <h3 className="text-2xl font-semibold text-white mb-6">Our Team</h3>
            <p className="text-lg text-gray-100 leading-relaxed">
              We are a diverse group of African Artificial Intelligence experts, data scientists, and software engineers passionate about using Artificial Intelligence to solve unique challenges that are encountered on the African continent.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-16">
          <div className="p-8 border-l-4" style={{ borderColor: theme.primary }}>
            <ul className="space-y-4">
              {listItems.map((item, index) => (
                <li key={index} className="flex items-start">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center mr-3 mt-1" style={{ backgroundColor: theme.primary }}>
                    <ChevronRight className="w-4 h-4 text-white" />
                  </div>
                  <p className="text-gray-700">{item}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;