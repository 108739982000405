import React from 'react';
import { Mail, ArrowRight } from 'lucide-react';
import theme from '../theme';

const Contact = () => {
  const Button = ({ children, onClick, className = '' }) => (
    <button 
      onClick={onClick}
      style={{
        backgroundColor: theme.accent,
        color: 'white',
      }}
      className={`px-8 py-3 rounded-full font-semibold transition duration-300 flex items-center justify-center hover:bg-[${theme.accentHover}] ${className}`}
    >
      {children}
      <ArrowRight className="ml-2 w-4 h-4" />
    </button>
  );

  return (
    <div className="min-h-screen bg-gray-50 py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">Contact Us</h2>
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto border-l-4 border-violet-500">
          <Mail className="text-violet-600 w-16 h-16 mb-6 mx-auto" />
          <p className="text-xl mb-8 text-gray-600 text-center">Get in touch with us to book a consultation or learn more about how MindClave can transform your African business with AI.</p>
          <form className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input type="text" id="name" name="name" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500" />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input type="email" id="email" name="email" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500" />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
              <textarea id="message" name="message" rows="4" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500"></textarea>
            </div>
            <div className="flex justify-center">
              <Button onClick={() => console.log('Form submitted')}>Send Message</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;