import React from 'react';
import { Brain, Cpu, Zap } from 'lucide-react';
import FeatureCard from './FeatureCard';

const AISolutionsSection = () => (
  <section className="py-24 bg-white">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">Our Solutions</h2>
      <p className="text-xl text-gray-600 text-center mb-12 max-w-2xl mx-auto">We build tailored technology solutions designed to address the unique challenges across the African continent.</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <FeatureCard 
          Icon={Brain}
          title="Intelligent Monitoring and Control"
          description="Build technology solutions that leverage the power of Artifical Intelligence for intelligent monitoring and control of systems or processes."
        />
        <FeatureCard 
          Icon={Cpu}
          title="Deep Learning"
          description="Leverage the power of data and state-of-the-art deep learning algorithms, uniquely developed for African contexts."
        />
        <FeatureCard 
          Icon={Zap}
          title="Custom Solution Development"
          description="Develop custom technology and Artficial Intelligece solutions for companies, institutions, and governments across Africa to address our unique challenges and enhance operational efficiency."
        />
      </div>
    </div>
  </section>
);

export default AISolutionsSection;