import React from 'react';
import { BarChart2, Users, ChevronRight } from 'lucide-react';
import theme from '../theme';

const Services = () => {
  const ServiceCard = ({ Icon, title, description, items }) => (
    <div className="bg-white p-8 rounded-lg shadow-lg border-t-4 border-violet-500">
      <div style={{backgroundColor: theme.primaryLight}} className="rounded-full w-16 h-16 flex items-center justify-center mb-6">
        <Icon style={{color: theme.primary}} className="w-8 h-8" />
      </div>
      <h3 className="text-2xl font-semibold mb-4 text-gray-800">{title}</h3>
      <p className="text-gray-600 mb-6 leading-relaxed">{description}</p>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-start">
            <ChevronRight className="flex-shrink-0 w-5 h-5 text-violet-500 mt-1 mr-2" />
            <span className="text-gray-600">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 py-24">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-4 text-center text-gray-800">Our Services</h2>
        <p className="text-xl text-gray-600 text-center mb-12 max-w-2xl mx-auto">Comprehensive AI solutions and consultation services tailored for African businesses.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <ServiceCard 
            Icon={BarChart2}
            title="AI Consultation and Strategy"
            description="Our expert consultants provide tailored guidance on integrating AI solutions into your business processes, with a focus on African markets and needs. We work with you to identify opportunities and develop AI strategies that drive growth."
            items={[
              "AI readiness assessment for African organizations",
              "Custom AI solution design for African use cases",
            ]}
          />
          <ServiceCard 
            Icon={Users}
            title="AI Solutions Development"
            description="We develop cutting-edge AI solutions tailored to the unique challenges and opportunities of African businesses. Our team leverages the latest in machine learning, natural language processing, and computer vision to create powerful, scalable AI tools."
            items={[
              "Custom Artifical Intelligence model development and training",
              "AI-powered analytics and business intelligence tools",
              "Integration of Artificial Intelligence solutions into existing systems"
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;