import React from 'react';
import theme from '../theme';
import Button from './Button';

const CTASection = () => (
  <section style={{backgroundColor: theme.primary}} className="text-white py-24">
    <div className="container mx-auto px-6 text-center">
      <h2 className="text-3xl font-bold mb-6">Do you have a problem we can help with?</h2>
      <p className="text-xl mb-10 max-w-2xl mx-auto">Our expert consultants are ready to guide you through the AI revolution. Book a consultation today and kickstart your journey of innovation.</p>
      <div className="flex justify-center">
        <Button 
          onClick={() => console.log('Book Consultation clicked')}
          inverse
        >
          Book a Consultation
        </Button>
      </div>
    </div>
  </section>
);

export default CTASection;