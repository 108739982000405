import React from 'react';
import theme from '../theme';
import Button from './Button';

const HeroSection = () => (
  <section className="relative bg-gray-900 text-white py-32 overflow-hidden">
    <div className="absolute inset-0 z-0">
      <img src="/api/placeholder/1920/1080" alt="African cityscape" className="w-full h-full object-cover opacity-20" />
      <div style={{backgroundColor: theme.primary}} className="absolute inset-0 opacity-90"></div>
    </div>
    <div className="container mx-auto px-6 relative z-10">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-5xl font-bold mb-6 leading-tight">
          Transforming{' '}
          <span className="inline-block px-2 py-1 bg-green-800 text-white">
            Africa
          </span>{' '}
          through Technological Innovation
        </h2>
        <p className="text-2xl mb-4 text-violet-200 font-semibold">Built by Africans, for Africa</p>
        <p className="text-xl mb-10 text-gray-300">Harness cutting-edge artificial intelligence to drive innovation, efficiency, and growth across the continent.</p>
        <div className="flex justify-center">
          <Button onClick={() => console.log('Book Consultation clicked')} inverse>Book a Consultation</Button>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;