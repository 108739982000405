import React from 'react';
import theme from '../theme';
import HeroSection from './HeroSection';
import AISolutionsSection from './AISolutionsSection';
import CTASection from './CTASection';

const Home = () => (
  <div style={{backgroundColor: theme.background.light}} className="min-h-screen">
    <HeroSection />
    <AISolutionsSection />
    <CTASection />
  </div>
);

export default Home;